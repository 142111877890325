import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from "../components/ui/button"
import { Label } from "../components/ui/label"
import { useUser } from '../context/UserContext'

export function DateDetailsPage() {
  const [budget, setBudget] = useState('')
  const [timeOfDay, setTimeOfDay] = useState('')
  const [duration, setDuration] = useState('')
  const [activityType, setActivityType] = useState('')
  const navigate = useNavigate();
  const { setUserDateDetails } = useUser();

  const handleSubmit = (e) => {
    e.preventDefault()
    const dateDetails = { budget, timeOfDay, duration, activityType }
    console.log('Selected options:', dateDetails)
    setUserDateDetails(dateDetails);
    navigate('/suggestions')  // Navigate to the suggestions page
  }

  const options = {
    budget: [
      { id: 'free', label: 'Free ($0)', emoji: '🆓' },
      { id: 'low', label: 'Low ($1 - $30)', emoji: '💰' },
      { id: 'medium', label: 'Medium ($31 - $100)', emoji: '💰💰' },
      { id: 'high', label: 'High ($101 and above)', emoji: '💰💰💰' },
    ],
    timeOfDay: [
      { id: 'morning', label: 'Morning', emoji: '🌅' },
      { id: 'afternoon', label: 'Afternoon', emoji: '☀️' },
      { id: 'evening', label: 'Evening', emoji: '🌙' },
      { id: 'flexible', label: 'Flexible', emoji: '🕰️' },
    ],
    duration: [
      { id: 'short', label: 'Short (1-2 hours)', emoji: '⏱️' },
      { id: 'half-day', label: 'Half-day (3-4 hours)', emoji: '⏳' },
      { id: 'full-day', label: 'Full-day', emoji: '🗓️' },
    ],
    activityType: [
      { id: 'food', label: 'Food & Dining', emoji: '🍽️' },
      { id: 'nature', label: 'Nature & Outdoors', emoji: '🌳' },
      { id: 'fitness', label: 'Fitness & Adventure', emoji: '🏋️‍♀️' },
      { id: 'arts', label: 'Arts & Culture', emoji: '🎨' },
      { id: 'entertainment', label: 'Entertainment & Games', emoji: '🎭' },
      { id: 'learning', label: 'Learning & Creativity', emoji: '🧠' },
      { id: 'relaxation', label: 'Relaxation & Wellness', emoji: '🧘‍♀️' },
    ],
  }

  const OptionGroup = ({ title, options, selected, setSelected }) => (
    <div className="space-y-4">
      <h2 className="text-3xl font-semibold text-pink-600">{title}</h2>
      <div className="space-y-3">
        {options.map((option) => (
          <div
            key={option.id}
            className={`
              p-4 rounded-lg cursor-pointer transition-all duration-300 ease-in-out
              ${selected === option.id
                ? 'bg-pink-300 transform scale-102'
                : 'bg-white bg-opacity-50 hover:bg-pink-200 hover:transform hover:scale-102'
              }
            `}
            onClick={() => setSelected(option.id)}
          >
            <Label className="flex items-center cursor-pointer">
              <span className={`text-lg ${selected === option.id ? 'text-pink-700' : 'text-pink-600'}`}>
                {option.emoji} {option.label}
              </span>
            </Label>
          </div>
        ))}
      </div>
    </div>
  )

  return (
    <div className="flex flex-col items-center justify-between min-h-screen bg-gradient-to-b from-pink-100 to-purple-100 py-16 px-4 animate-fade-in">
      <div className="w-full max-w-4xl text-center mb-12 animate-scale-in">
        <h1 className="text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-purple-600 mb-6">
          Last Step Before Your Perfect Date!
        </h1>
        <p className="text-2xl font-medium text-purple-700">
          Let's fine-tune the details for your ideal date experience.
        </p>
      </div>
      <div className="max-w-md w-full">
        <form onSubmit={handleSubmit} className="space-y-10">
          <OptionGroup
            title="Budget"
            options={options.budget}
            selected={budget}
            setSelected={setBudget}
          />
          <OptionGroup
            title="Time of Day"
            options={options.timeOfDay}
            selected={timeOfDay}
            setSelected={setTimeOfDay}
          />
          <OptionGroup
            title="Duration"
            options={options.duration}
            selected={duration}
            setSelected={setDuration}
          />
          <OptionGroup
            title="Activity Type"
            options={options.activityType}
            selected={activityType}
            setSelected={setActivityType}
          />
          <div className="text-center text-sm text-gray-700 bg-white bg-opacity-50 rounded-lg p-3 animate-float">
            These final details will help us curate the perfect date experience just for you! 💖✨
          </div>
          <Button
            type="submit"
            disabled={!budget || !timeOfDay || !duration || !activityType}
            className="w-full text-lg font-medium bg-gradient-to-r from-pink-500 to-purple-500 hover:from-pink-600 hover:to-purple-600 text-white rounded-full py-3 px-4 shadow-lg transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-xl disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Find My Perfect Date!
          </Button>
        </form>
      </div>
    </div>
  )
}

const styles = `
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes scaleIn {
  from { transform: scale(0.9); }
  to { transform: scale(1); }
}

@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

.animate-fade-in {
  animation: fadeIn 1s ease-out;
}

.animate-scale-in {
  animation: scaleIn 0.5s ease-out;
}

.animate-float {
  animation: float 3s ease-in-out infinite;
}
`

if (typeof document !== 'undefined') {
  const styleElement = document.createElement('style')
  styleElement.textContent = styles
  document.head.appendChild(styleElement)
}

export default DateDetailsPage

