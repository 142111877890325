import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from "./ui/button"

export function LandingPage() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-pink-100 to-purple-100 p-4 text-center animate-fade-in">
      <div className="max-w-md w-full space-y-12">
        <div className="animate-scale-in">
          <h1 className="text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-purple-600">
            DeliberateDates
          </h1>
          <p className="mt-4 -mb-3 text-2xl font-medium text-purple-700 animate-float-lower">
            Let us plan the date for you!
          </p>
        </div>
        <div className="space-y-6">
          <FeatureItem text={<>🔍 No More Google Searches 🔍</>} />
          <FeatureItem text={<>😍 Go on Dates that fit the Vibes 😍</>} />
          <FeatureItem text={<>🥳 Less Stress, More Fun 🥳</>} />
        </div>
        <Button 
          onClick={() => navigate('/phonenumber')}
          className="w-full text-lg font-medium bg-gradient-to-r from-pink-500 to-purple-500 hover:from-pink-600 hover:to-purple-600 text-white rounded-full py-4 px-6 shadow-lg transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-xl"
        >
          Find Your Next Date!
        </Button>
      </div>
    </div>
  )
}

function FeatureItem({ text }) {
  return (
    <div className="flex items-center justify-center space-x-3 animate-slide-in">
      <span className="text-lg text-gray-700 bg-white bg-opacity-50 rounded-full py-2 px-4 shadow-md hover:shadow-lg transition duration-300 ease-in-out transform hover:scale-105">
        {text}
      </span>
    </div>
  )
}

const styles = `
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes scaleIn {
  from { transform: scale(0.9); }
  to { transform: scale(1); }
}

@keyframes slideIn {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

@keyframes floatLower {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(20px); }
}

@keyframes floatLower {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(10px); }
}

.animate-fade-in {
  animation: fadeIn 1s ease-out;
}

.animate-scale-in {
  animation: scaleIn 0.5s ease-out;
}

.animate-slide-in {
  animation: slideIn 0.5s ease-out;
}

.animate-float {
  animation: float 3s ease-in-out infinite;
}

.animate-float-lower {
  animation: floatLower 3s ease-in-out infinite;
}
`

if (typeof document !== 'undefined') {
  const styleElement = document.createElement('style')
  styleElement.textContent = styles
  document.head.appendChild(styleElement)
}

export default LandingPage
