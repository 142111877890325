import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from "../components/ui/button"
import { Input } from "../components/ui/input"
import { Label } from "../components/ui/label"
import { useUser } from '../context/UserContext'

export function PhoneNumberPage() {
  const { phoneNumber, setPhoneNumber } = useUser();
  const navigate = useNavigate();

  const handlePhoneNumberChange = (e) => {
    const input = e.target.value.replace(/\D/g, '')
    const formatted = input.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
    setPhoneNumber(formatted)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log('Phone number submitted:', phoneNumber)
    navigate('/location')  // Changed from '/page3' to '/location'
  }

  const buttonClasses = phoneNumber
    ? "w-full text-lg font-medium bg-gradient-to-r from-pink-500 to-purple-500 hover:from-pink-600 hover:to-purple-600 text-white rounded-full py-3 px-4 shadow-lg transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-xl"
    : "w-full text-lg font-medium bg-gradient-to-r from-pink-300 to-purple-300 text-white rounded-full py-3 px-4 shadow-lg transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-xl cursor-not-allowed";

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-pink-100 to-purple-100 p-4 text-center animate-fade-in">
      <div className="max-w-md w-full space-y-8">
        <div className="animate-scale-in">
          <h1 className="text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-purple-600">
            First Things First!
          </h1>
          <p className="mt-4 text-xl font-medium text-purple-700">
            Your digits before we get you ready for the perfect date.
          </p>
        </div>
        <form onSubmit={handleSubmit} className="mt-8 space-y-6">
          <div className="rounded-md shadow-sm -space-y-px">
            <Label htmlFor="phone-number" className="sr-only">Phone Number</Label>
            <Input
              id="phone-number"
              name="phone-number"
              type="tel"
              required
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-pink-500 focus:border-pink-500 focus:z-10 sm:text-sm"
              placeholder="(123) 456-7890"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
            />
          </div>
          <div className="text-sm text-gray-600 bg-white bg-opacity-50 rounded-lg p-3 animate-float">
            The phone number is to prevent bots from spamming us. For now, our dates are for humans like you! 🤖❌👫✅
          </div>
          <Button
            type="submit"
            className={buttonClasses}
            disabled={!phoneNumber}
          >
            Let's Find Your Date!
          </Button>
        </form>
      </div>
    </div>
  )
}

const styles = `
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes scaleIn {
  from { transform: scale(0.9); }
  to { transform: scale(1); }
}

@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

.animate-fade-in {
  animation: fadeIn 1s ease-out;
}

.animate-scale-in {
  animation: scaleIn 0.5s ease-out;
}

.animate-float {
  animation: float 3s ease-in-out infinite;
}
`

if (typeof document !== 'undefined') {
  const styleElement = document.createElement('style')
  styleElement.textContent = styles
  document.head.appendChild(styleElement)
}

export default PhoneNumberPage
