import React, { useState, useEffect, useCallback } from 'react';
import { useUser } from '../context/UserContext';
import { Button } from "../components/ui/button";

export function DateSuggestionsPage() {
  const [suggestions, setSuggestions] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { 
    userLocation, 
    userCommuteTime, 
    userVibe, 
    userDateDetails 
  } = useUser();

  const fetchSuggestions = useCallback(async () => {
  setLoading(true);
  setError(null);
  try {
    console.log('Fetching suggestions...');

    // Assuming you have a token available for authentication
    const token = process.env.OPENAI_API_KEY; // Replace this with the actual token or get it from a context or state

    const response = await fetch('https://delibera-git-f68e68-lintoncamachoinvestments-gmailcoms-projects.vercel.app/api/date-suggestions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,  // Add the Authorization header here
      },
      body: JSON.stringify({
        userLocation,
        userCommuteTime,
        userVibe,
        userDateDetails,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log('Raw API response:', data.suggestions);
    const processedSuggestions = processSuggestions(data.suggestions);
    console.log('Processed suggestions:', processedSuggestions);
    setSuggestions(processedSuggestions);
  } catch (err) {
    if (err.message.includes('CORS') || err.message.includes('401')) {
      console.error('CORS or preflight error:', err);
    } else {
      console.error('Error fetching suggestions:', err);
    }
    setError(err.message || 'An error occurred while fetching suggestions');
  } finally {
    setLoading(false);
  }
}, [userLocation, userCommuteTime, userVibe, userDateDetails]);


  useEffect(() => {
    fetchSuggestions();
  }, [fetchSuggestions]);

  // Working here
  function processSuggestions(suggestionsString) {
    console.log('Processing suggestions string:', suggestionsString);
    const suggestions = suggestionsString.split(/\d+\.\s+\*\*/).slice(1); // Split based on numbered list

    return suggestions.map(suggestion => {
        const [title, ...details] = suggestion.split('\n').map(s => s.trim()).filter(Boolean); // Split by line and trim
        const detailsObj = {};
        let currentKey = '';
        let webLinks = [];
        let inWebLinksSection = false; // Track if we are in the Web Links section

        details.forEach(detail => {
            // Detect if the line is starting the Web Links section
            if (detail.includes('Web Links')) {
                inWebLinksSection = true; // Start processing web links
                return; // Skip this line since it's the Web Links header
            }

            if (inWebLinksSection) {
                // Now we're inside the Web Links section
                const matches = [...detail.matchAll(/\{\{\s*"title":\s*"(.*?)",\s*"url":\s*"(.*?)"\s*\}\}/g)];

                // If we find valid matches for title-url objects
                if (matches.length > 0) {
                    matches.forEach(match => {
                        webLinks.push({
                            title: match[1],
                            url: match[2]
                        });
                    });
                }
            } else if (detail.includes(':')) {
                // Handle regular suggestion details (not in Web Links section)
                const [key, value] = detail.split(':').map(s => s.trim());
                currentKey = key.replace(/\*\*|-/g, '').trim().toLowerCase(); // Cleaning up the key
                detailsObj[currentKey] = value;
            }
        });

        // Add the web links to the details object
        detailsObj['web links'] = webLinks;

        // Return the parsed suggestion with the title and other details
        return { title: title.replace(/\*\*/g, ''), ...detailsObj };
    });
}
//Working here

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-pink-100 to-purple-100 p-4 text-center animate-fade-in">
        <div className="max-w-md w-full space-y-8">
          <div className="animate-pulse">
            <h1 className="text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-purple-600">
              Creating Your Perfect Date...
            </h1>
            <p className="mt-4 text-xl font-medium text-purple-700">
              Just a moment while we craft some amazing suggestions for you!
            </p>
          </div>
          <div className="flex justify-center">
            <div className="w-16 h-16 border-t-4 border-pink-500 border-solid rounded-full animate-spin"></div>
          </div>
          <div className="text-sm text-gray-600 bg-white bg-opacity-50 rounded-lg p-3 animate-bounce">
            Exciting date ideas coming your way! 💖✨
          </div>
        </div>
      </div>
    );
  }

  if (error) return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-pink-100 to-purple-100 p-4 text-center">
      <h1 className="text-2xl font-bold text-red-600 mb-4">Error</h1>
      <p className="text-gray-700">{error}</p>
      <Button
        onClick={fetchSuggestions}
        className="mt-4 px-4 py-2 bg-pink-500 text-white rounded hover:bg-pink-600"
      >
        Try Again
      </Button>
    </div>
  );

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-pink-100 to-purple-100 p-4 text-center animate-fade-in">
      <div className="max-w-4xl w-full space-y-8">
        <h1 className="text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-purple-600 mb-8 mt-4">
          Your Perfect Date Suggestions
        </h1>
        {suggestions && suggestions.map((suggestion, index) => (
          <div key={index} className="bg-white bg-opacity-75 rounded-lg p-6 shadow-lg mb-6 text-left">
            <h2 className="text-2xl font-bold text-pink-600 mb-4">{suggestion.title}</h2>
            <div className="flex flex-col md:flex-row">
              <div className="flex-grow">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {Object.entries(suggestion).map(([key, value]) => 
                    key !== 'title' && key !== 'web links' && (
                      <div key={key} className="mb-2">
                        <p className="font-semibold text-purple-700 capitalize">{key}:</p>
                        <p className="text-gray-700">{value}</p>
                      </div>
                    )
                  )}
                </div>
              </div>
              {suggestion['web links'] && suggestion['web links'].length > 0 && (
                <div className="mt-6 md:mt-0 md:ml-3 md:w-1/2">
                  <p className="font-semibold text-purple-700 capitalize mb-3">
                    Useful Links:
                  </p>
                  <div className="flex flex-col gap-3">
                    {suggestion['web links'].map((link, i) => (
                      <a 
                        key={i}
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-block bg-pink-100 hover:bg-pink-200 text-pink-800 font-semibold py-2 px-4 rounded-full transition duration-300 text-center"
                      >
                        {link.title || 'Visit Site'}
                      </a>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
        <Button
          onClick={fetchSuggestions}
          className="w-full text-lg font-medium bg-gradient-to-r from-pink-500 to-purple-500 hover:from-pink-600 hover:to-purple-600 text-white rounded-full py-3 px-4 shadow-lg transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-xl mt-8"
        >
          Generate New Suggestions
        </Button>
      </div>
    </div>
  );
}  

export default DateSuggestionsPage;
