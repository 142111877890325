import React, { createContext, useState, useContext } from 'react';

const UserContext = createContext();

export function UserProvider({ children }) {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [userLocation, setUserLocation] = useState('');
  const [userCommuteTime, setUserCommuteTime] = useState('');
  const [userVibe, setUserVibe] = useState('');
  const [userDateDetails, setUserDateDetails] = useState({});

  return (
    <UserContext.Provider value={{ 
      phoneNumber, setPhoneNumber,
      userLocation, setUserLocation,
      userCommuteTime, setUserCommuteTime,
      userVibe, setUserVibe,
      userDateDetails, setUserDateDetails
    }}>
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  return useContext(UserContext);
}