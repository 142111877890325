import React from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { UserProvider } from './context/UserContext'
import { LandingPage } from './components/LandingPage'
import { PhoneNumberPage } from './components/PhoneNumberPage'
import { LocationPage } from './components/LocationPage'
import { VibesPage } from './components/VibesPage'
import { DateDetailsPage } from './components/DateDetailsPage'
import { DateSuggestionsPage } from './components/DateSuggestionsPage'

function App() {
  return (
    <UserProvider>
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/phonenumber" element={<PhoneNumberPage />} />
          <Route path="/location" element={<LocationPage />} />
          <Route path="/vibes" element={<VibesPage />} />
          <Route path="/datedetails" element={<DateDetailsPage />} />
          <Route path="/suggestions" element={<DateSuggestionsPage />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    </UserProvider>
  )
}

export default App

