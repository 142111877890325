import React, { useState, useRef, useEffect } from 'react'

export const Select = React.forwardRef(({ children, value, onValueChange, ...props }, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelect = (selectedValue) => {
    onValueChange(selectedValue);
    setIsOpen(false);
  };

  return (
    <div ref={ref} className="relative" {...props}>
      <div 
        onClick={() => setIsOpen(!isOpen)} 
        className="cursor-pointer p-2 border border-gray-300 rounded-md"
      >
        {value || 'Select commute time'}
      </div>
      {isOpen && (
        <div ref={dropdownRef} className="absolute top-full left-0 w-full bg-white border border-gray-300 mt-1 rounded-md shadow-lg z-50">
          {React.Children.map(children, child => 
            React.cloneElement(child, {
              onClick: () => handleSelect(child.props.children)
            })
          )}
        </div>
      )}
    </div>
  )
})

export const SelectTrigger = React.forwardRef(({ children, className, ...props }, ref) => {
  return (
    <div ref={ref} className={`${className}`} {...props}>
      {children}
    </div>
  )
})

export const SelectValue = React.forwardRef(({ children, placeholder, ...props }, ref) => {
  return (
    <span ref={ref} {...props}>
      {children || placeholder}
    </span>
  )
})

export const SelectContent = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <>{children}</>
  )
})

export const SelectItem = React.forwardRef(({ children, onClick, ...props }, ref) => {
  return (
    <div ref={ref} onClick={onClick} className="p-2 hover:bg-gray-100 cursor-pointer" {...props}>
      {children}
    </div>
  )
})