import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from "../components/ui/button"
import { Label } from "../components/ui/label"
import { useUser } from '../context/UserContext'

export function VibesPage() {
  const [selectedBucket, setSelectedBucket] = useState('')
  const navigate = useNavigate();
  const { setUserVibe } = useUser();

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log('Selected bucket:', selectedBucket)
    setUserVibe(selectedBucket);
    navigate('/datedetails')  // Changed from '/page5' to '/datedetails'
  }

  const buckets = [
    {
      id: 'low-key',
      emoji: ['☕️', '🌳'],
      title: 'Low-Key Vibes',
      description: 'Chill, laid-back hangout (like coffee shops, park strolls, a chill bar night, or casual meetups)'
    },
    {
      id: 'adventurous',
      emoji: ['🧗‍♀️', '🔓'],
      title: 'Adventurous AF',
      description: 'Spontaneous, high-energy dates (like hiking, escape rooms, or trying something new)'
    },
    {
      id: 'main-character',
      emoji: ['🎭', '🎉'],
      title: 'Main Character Energy',
      description: 'All-out, unforgettable experiences (like concerts, rooftop dinners, or festivals)'
    },
    {
      id: 'in-your-feels',
      emoji: ['🌠', '🍽️'],
      title: 'In Your Feels',
      description: 'Deeper, more meaningful dates (like museums, intimate dinners, or star-gazing)'
    }
  ]

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-pink-100 to-purple-100 p-4 text-center animate-fade-in">
      <div className="max-w-md w-full space-y-4">
        <div className="animate-scale-in">
          <h1 className="text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-purple-600">
            What's Your Vibe?
          </h1>
          <p className="mt-4 text-xl font-medium text-purple-700">
            Choose the energy you're looking for in your next date!
          </p>
        </div>
        <form onSubmit={handleSubmit} className="mt-8 space-y-6">
          <div className="space-y-4">
            {buckets.map((bucket) => (
              <div
                key={bucket.id}
                className={`
                  p-4 rounded-lg cursor-pointer transition-all duration-300 ease-in-out
                  ${selectedBucket === bucket.id
                    ? 'bg-pink-300 transform scale-105'
                    : 'bg-white bg-opacity-50 hover:bg-pink-200 hover:transform hover:scale-105'
                  }
                `}
                onClick={() => setSelectedBucket(bucket.id)}
              >
                <Label className="flex flex-col items-center cursor-pointer">
                  <span className={`font-semibold text-lg ${selectedBucket === bucket.id ? 'text-pink-700' : 'text-pink-600'}`}>
                    {bucket.emoji[0]} {bucket.title} {bucket.emoji[1]}
                  </span>
                  <span className={`text-sm mt-2 ${selectedBucket === bucket.id ? 'text-gray-800' : 'text-gray-700'}`}>
                    {bucket.description}
                  </span>
                </Label>
              </div>
            ))}
          </div>
          <div className="text-sm text-gray-700 bg-white bg-opacity-50 rounded-lg p-3 animate-float">
            Your choice helps us curate the perfect date experience for you! 💖✨
          </div>
          <Button
            type="submit"
            disabled={!selectedBucket}
            className="w-full text-lg font-medium bg-gradient-to-r from-pink-500 to-purple-500 hover:from-pink-600 hover:to-purple-600 text-white rounded-full py-3 px-4 shadow-lg transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-xl disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Let's Grab a Few More Details!
          </Button>
        </form>
      </div>
    </div>
  )
}

const styles = `
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes scaleIn {
  from { transform: scale(0.9); }
  to { transform: scale(1); }
}

@keyframes slideIn {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

.animate-fade-in {
  animation: fadeIn 1s ease-out;
}

.animate-scale-in {
  animation: scaleIn 0.5s ease-out;
}

.animate-slide-in {
  animation: slideIn 0.5s ease-out;
}

.animate-float {
  animation: float 3s ease-in-out infinite;
}
`

if (typeof document !== 'undefined') {
  const styleElement = document.createElement('style')
  styleElement.textContent = styles
  document.head.appendChild(styleElement)
}

export default VibesPage