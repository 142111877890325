import React, { useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from "../components/ui/button"
import { Input } from "../components/ui/input"
import { Label } from "../components/ui/label"
import { Select, SelectItem } from "../components/ui/select"  // Remove unused imports
import { useUser } from '../context/UserContext'

export function LocationPage() {  // Changed from Page3 to LocationPage
  const [location, setLocation] = useState('')
  const [commuteTime, setCommuteTime] = useState('')
  const navigate = useNavigate();
  const { setUserLocation, setUserCommuteTime } = useUser();

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log('Location:', location)
    console.log('Commute time:', commuteTime)
    setUserLocation(location);
    setUserCommuteTime(commuteTime);
    navigate('/vibes')  // Changed from '/page4' to '/vibes'
  }

  const isFormComplete = useMemo(() => {
    return location.trim() !== '' && commuteTime !== '';
  }, [location, commuteTime]);

  const buttonClasses = isFormComplete
    ? "w-full text-lg font-medium bg-gradient-to-r from-pink-500 to-purple-500 hover:from-pink-600 hover:to-purple-600 text-white rounded-full py-3 px-4 shadow-lg transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-xl"
    : "w-full text-lg font-medium bg-gradient-to-r from-pink-300 to-purple-300 text-white rounded-full py-3 px-4 shadow-lg transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-xl cursor-not-allowed";

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-pink-100 to-purple-100 p-4 text-center animate-fade-in">
      <div className="max-w-md w-full space-y-8">
        <div className="animate-scale-in">
          <h1 className="text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-purple-600">
            Set Your Scene
          </h1>
          <p className="mt-4 text-xl font-medium text-purple-700">
            Let's find the perfect date in your area!
          </p>
        </div>
        <form onSubmit={handleSubmit} className="mt-8 space-y-6">
          <div className="rounded-md shadow-sm -space-y-px">
            <Label htmlFor="location" className="sr-only">Location</Label>
            <Input
              id="location"
              name="location"
              type="text"
              required
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-pink-500 focus:border-pink-500 focus:z-10 sm:text-sm"
              placeholder="Enter your location"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            />
          </div>
          <div className="rounded-md shadow-sm">
            <Label htmlFor="commute-time" className="block text-sm font-medium text-gray-700 mb-1">
              How far are you willing to travel?
            </Label>
            <Select value={commuteTime} onValueChange={setCommuteTime}>
              <SelectItem>5 minutes</SelectItem>
              <SelectItem>15 minutes</SelectItem>
              <SelectItem>30 minutes</SelectItem>
              <SelectItem>1 hour</SelectItem>
              <SelectItem>1.5 hours</SelectItem>
            </Select>
          </div>
          <div className="text-sm text-gray-600 bg-white bg-opacity-50 rounded-lg p-3 animate-float">
            We'll use this info to suggest date spots that are convenient for you. Adventure awaits! 🗺️✨
          </div>
          <Button
            type="submit"
            className={buttonClasses}
            disabled={!isFormComplete}
          >
            Let's Get to the Vibes!
          </Button>
        </form>
      </div>
    </div>
  )
}

export default LocationPage  // Changed from Page3 to LocationPage
